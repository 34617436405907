import rect from "../../Assets/rect.png"
import "./404.css"

export default function ConstructionPage() {
    return (
        <>
            <main>
                <section className="advice">
                    <h1 className="advice__title text-[3rem] mb-[0px] text-white text-center font-sans font-bold w-[17em] sm:w-[10em] sm:mt-[-10rem] sm:text-[1.5rem] xl:w-[14em] md:mb-[1rem] md:w-[10em] lg:w-[10em] lg:mb-[12rem] lg:text-[2.5rem] md:text-[2rem]">The on-demand, all inclusive,
                        healthcare management ecosystem.</h1>
                    <img src={rect} alt="rectangle" className="mt-[30px] sm:mt-[20px] lg:pt-[5rem] lg:absolute md:pb-[10rem]"/>
                    <h1 className="mt-[135px] text-[2rem] sm:mt-[4.7rem] sm:text-[1rem] sm:absolute md:text-[1.3rem] md:mt-[200px] md:absolute text-gray-200 lg:text-[1.4rem] lg:absolute lg:mt-[300px] text-center leading-[3.5rem]">site under construction</h1>
                </section>
                <section className="city-stuff">
                    <ul className="skyscrappers__list">
                        <li className="skyscrapper__item skyscrapper-1" />
                        <li className="skyscrapper__item skyscrapper-2" />
                        <li className="skyscrapper__item skyscrapper-3" />
                        <li className="skyscrapper__item skyscrapper-4" />
                        <li className="skyscrapper__item skyscrapper-5" />
                    </ul>
                    <ul className="tree__container">
                        <li className="tree__list">
                            <ul className="tree__item tree-1">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-2">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-3">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-4">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-5">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-6">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-7">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                            <ul className="tree__item tree-8">
                                <li className="tree__trunk" />
                                <li className="tree__leaves" />
                            </ul>
                        </li>
                    </ul>
                    <ul className="crane__list crane-1">
                        <li className="crane__item crane-cable crane-cable-1" />
                        <li className="crane__item crane-cable crane-cable-2" />
                        <li className="crane__item crane-cable crane-cable-3" />
                        <li className="crane__item crane-stand" />
                        <li className="crane__item crane-weight" />
                        <li className="crane__item crane-cabin" />
                        <li className="crane__item crane-arm" />
                    </ul>
                    <ul className="crane__list crane-2">
                        <li className="crane__item crane-cable crane-cable-1" />
                        <li className="crane__item crane-cable crane-cable-2" />
                        <li className="crane__item crane-cable crane-cable-3" />
                        <li className="crane__item crane-stand" />
                        <li className="crane__item crane-weight" />
                        <li className="crane__item crane-cabin" />
                        <li className="crane__item crane-arm" />
                    </ul>
                    <ul className="crane__list crane-3">
                        <li className="crane__item crane-cable crane-cable-1" />
                        <li className="crane__item crane-cable crane-cable-2" />
                        <li className="crane__item crane-cable crane-cable-3" />
                        <li className="crane__item crane-stand" />
                        <li className="crane__item crane-weight" />
                        <li className="crane__item crane-cabin" />
                        <li className="crane__item crane-arm" />
                    </ul>
                </section>
            </main>

        </>
    )
}